import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { handleProblem } from '../../problems/problem.service';

@Injectable()
export class DeletePartnerUserService {

  private apiUrl = environment.rest.apiV2Url;

  public constructor(private client: HttpClient) {}

  public partnerUserDelete(id: string): Observable<any> {
    const url = `${this.apiUrl}/partner-users/${id}`;

    return this.client.delete<any>(url)
    .pipe(
      catchError(error => throwError(handleProblem(error)))
    );
  }
}