import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeletePartnerNotificationEmailService } from './delete-partner-notification-email/delete-partner-notification-email.service';
import { AddPartnerNotificationEmailService } from './add-partner-notification-email/add-partner-notification-email.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    DeletePartnerNotificationEmailService,
    AddPartnerNotificationEmailService
  ],
})
export class PartnerNotificationEmailsModule {}
