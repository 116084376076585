import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskReportGetService } from './task-report-get/task-report-get.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    TaskReportGetService,
  ],
})
export class TaskReportsModule {}
