import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdatePartnerService } from './update/update-partner.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    UpdatePartnerService
  ],
})
export class PartnersModule {}
