import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { handleProblem } from '../../problems/problem.service';
import { AddPartnerNotificationEmailInterface } from '../definitions/add-partner-notification-email.interface';
import { PartnerNotificationEmailAddResponseInterface } from '../definitions/partner-notification-email-add-response.interface';

@Injectable()
export class AddPartnerNotificationEmailService {

  private apiUrl = environment.rest.apiV2Url;

  public constructor(private client: HttpClient) {}

  public partnerEmailNotificationAdd(addPartnerNotificationEmail: AddPartnerNotificationEmailInterface): Observable<PartnerNotificationEmailAddResponseInterface> {
    const url = `${this.apiUrl}/partner-notification-emails`;

    return this.client.post<PartnerNotificationEmailAddResponseInterface>(url, addPartnerNotificationEmail)
    .pipe(
      catchError(error => throwError(handleProblem(error)))
    );
  }
}