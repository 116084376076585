import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthApiV2Module } from './auth/auth-api-v2.module';
import { CentralEventsModule } from './central-events/central-events.module';
import { DamageEventsModule } from './damage-events/damage-events.module';
import { TaskReserveModule } from './task-reserve/task-reserve.module';
import { TasksModule } from './tasks/tasks.module';
import { GTNetModule } from './gtnet/gtnet.module';
import { PartnersModule } from './partners/partners.module';
import { TaskProtectedValueModule } from './task-protected-value/task-protected-value.module';
import { TaskReportsModule } from './task-reports/task-reports.module';
import { PartnerUsersModule } from './partner-users/partner-users.module';
import { PartnerNotificationEmailsModule } from './partner-notification-emails/partner-notification-emails.module';

@NgModule({
  imports: [CommonModule],
  exports: [
    AuthApiV2Module,
    PartnerUsersModule,
    PartnerNotificationEmailsModule,
    PartnersModule,
    CentralEventsModule,
    GTNetModule,
    DamageEventsModule,
    TaskReserveModule,
    TaskProtectedValueModule,
    TaskReportsModule,
    TasksModule,
  ],
})
export class RestModule {}
