import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, throwError} from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { handleProblem } from '../../problems/problem.service';
import { TaskReportGetQueryParamsInterface } from '../definitions/task-report-get-query-params.interface';
import { createQueryParams } from './task-report-get-query-params-factory';
import { parseContentDispositionFilename } from '../../../../../../../utils/content-disposition-parser';
import { FileResponseInterface } from '../../../../definitions/file-response.interface';

@Injectable()
export class TaskReportGetService {
  private apiUrl: string = environment.rest.apiV2Url;

  constructor(private http: HttpClient) {}

  public getTaskReport(paramsObj: TaskReportGetQueryParamsInterface): Observable<FileResponseInterface> {
    const url: string = `${this.apiUrl}/task-reports`;
    const params: HttpParams = createQueryParams(paramsObj);

    return this.http.get(url, { params, responseType: 'blob', observe: "response" }).pipe(
      map((response: HttpResponse<Blob>): FileResponseInterface => {
        const contentDisposition: string = response.headers.get('content-disposition');
        const filename: string = parseContentDispositionFilename(contentDisposition);

        return { blob: response.body as Blob, filename: filename };
      }),
      catchError(error => throwError(handleProblem(error)))
    );
  }
}
