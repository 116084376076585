import { TaskReportGetQueryParamsInterface } from '../definitions/task-report-get-query-params.interface';
import { HttpParams } from '@angular/common/http';

export function createQueryParams(paramsObj: TaskReportGetQueryParamsInterface): HttpParams {
  let params: HttpParams = new HttpParams();

  if (paramsObj.CreatedAtFrom !== undefined && paramsObj.CreatedAtFrom !== null) {
    params = params.set('CreatedAtFrom', paramsObj.CreatedAtFrom.toISOString());
  }

  if (paramsObj.CreatedAtTo !== undefined && paramsObj.CreatedAtTo !== null) {
    params = params.set('CreatedAtTo', paramsObj.CreatedAtTo.toISOString());
  }

  if (paramsObj.PartnerIds !== undefined && paramsObj.PartnerIds !== null && paramsObj.PartnerIds.length > 0) {
    paramsObj.PartnerIds.forEach(id => {
      params = params.append('PartnerIds', id);
    });
  }

  if (paramsObj.TaskStates !== undefined && paramsObj.TaskStates !== null && paramsObj.TaskStates.length > 0) {
    paramsObj.TaskStates.forEach(name => {
      params = params.append('TaskStates', name);
    });
  }

  if (paramsObj.ProtectedValue !== undefined && paramsObj.ProtectedValue !== null) {
    params = params.set('ProtectedValue', paramsObj.ProtectedValue.toString());
  }

  if (paramsObj.ProtectedValueOperator !== undefined && paramsObj.ProtectedValueOperator !== null) {
    params = params.set('ProtectedValueOperator', paramsObj.ProtectedValueOperator);
  }

  if (paramsObj.Reserve !== undefined && paramsObj.Reserve !== null) {
    params = params.set('Reserve', paramsObj.Reserve.toString());
  }

  if (paramsObj.ReserveValueOperator !== undefined && paramsObj.ReserveValueOperator !== null) {
    params = params.set('ReserveValueOperator', paramsObj.ReserveValueOperator);
  }

  if (paramsObj.TaskTagIds !== undefined && paramsObj.TaskTagIds !== null && paramsObj.TaskTagIds.length > 0) {
    paramsObj.TaskTagIds.forEach(id => {
      params = params.append('TaskTagIds', id);
    });
  }

  return params;
}
