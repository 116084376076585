export function parseContentDispositionFilename(contentDisposition: string): string {
  let filename: string = 'download';

  if (!contentDisposition) {
    return filename;
  }

  // Regex:
  // - filename\*=UTF-8''([^;]+) captures the RFC 5987 filename (until a semicolon)
  // - filename=([^;]+) captures the basic filename (until a semicolon)
  const regex: RegExp = /filename\*=UTF-8''([^;]+)|filename=([^;]+)/i;
  const matches: RegExpExecArray = regex.exec(contentDisposition);

  if (matches) {
    if (matches[1]) {
      // If the RFC 5987 group matched, decode it.
      filename = decodeURIComponent(matches[1]);
    } else if (matches[2]) {
      // Clean any surrounding quotes if present.
      filename = matches[2].trim().replace(/^"|"$/g, '');
    }
  }

  return filename;
}
