import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeletePartnerUserService } from './delete-partner-user/delete-partner-user.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    DeletePartnerUserService
  ],
})
export class PartnerUsersModule {}
