import { HttpErrorResponse } from '@angular/common/http';
import { ProblemInterface } from './definitions/problem.interface';

export function handleProblem(error: HttpErrorResponse): ProblemInterface {
  return {
    type: error.error?.type || 'Unknown Error',
    title: error.error?.title || 'An error occurred',
    status: error.status,
    detail: error.error?.detail,
    instance: error.url,
    errors: error.error?.errors || null
  };
}
