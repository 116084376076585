import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { authRestoreApiV2Token, authRestoreRefreshToken, authStoreApiV2Token, authStoreRefreshToken } from '../utils';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthApiV2Service } from '../services/auth-api-v2/auth-api-v2.service';
import { ProblemInterface } from '../services/rest/problems/definitions/problem.interface';
import { AuthRefreshTokenInterface } from '../services/rest/auth/definitions/auth-refresh-token.interface';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private forbiddenStatusCode: number = 401;
  private apiUrl: string = environment.rest.apiV2Url;
  private refreshTokenUrl: string = `${environment.rest.apiV2Url}/auth/refresh-token`;
  private snackBarShown: boolean = false;

  constructor(
    private authService: AuthApiV2Service,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isRefreshTokenEndpoint = req.url.includes(this.refreshTokenUrl) === true;
    if (isRefreshTokenEndpoint === true) {
      return next.handle(req);
    }

    const isJwtAuthorizedEndpoint = req.url.includes(this.apiUrl) === true;
    const authToken = authRestoreApiV2Token();

    // Add JWT access token only for requests on new dotnet API
    if (isJwtAuthorizedEndpoint === false || authToken === null) {
      return next.handle(req).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === this.forbiddenStatusCode) {
            this.handleExpiredSession();
          }

          return throwError(error);
        }),
        map((res) => {
          const status = this.extractStatusFromResponse(res);
          if (status === this.forbiddenStatusCode && this.snackBarShown === false) {
            this.snackBarShown = true;
            this.handleExpiredSession();
          }

          return res;
        })
      );
    }

    const modifiedReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${authToken}`
      }
    });

    return next.handle(modifiedReq).pipe(
      catchError((error: HttpErrorResponse): Observable<any> => {
        const refreshToken = authRestoreRefreshToken();

        if (error.status === this.forbiddenStatusCode && refreshToken) {
          return this.handleRefreshToken(refreshToken, modifiedReq, next);
        }

        return throwError(error);
      })
    );
  }

  private handleRefreshToken(
    refreshToken: string,
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authService.refreshToken(refreshToken).pipe(
      switchMap((newAuthResponse: AuthRefreshTokenInterface): Observable<any> => {
        if (!newAuthResponse || !newAuthResponse.token) {
          this.handleExpiredSession();
          return throwError('Unable to refresh token');
        }

        authStoreApiV2Token(newAuthResponse.token);
        authStoreRefreshToken(newAuthResponse.refreshToken);

        const clonedReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${newAuthResponse.token}`,
          },
        });

        return next.handle(clonedReq);
      }),
      catchError((error: ProblemInterface) => {
        if (error.status === this.forbiddenStatusCode) {
          this.handleExpiredSession();
          return throwError(error);
        }

        return throwError(error);
      })
    );
  }

  private handleExpiredSession(): void {
    if (this.snackBarShown === true) {
      return;
    }

    this.snackBarShown = true;
    const returnUrl = this.router.url;

    this.router.navigate(['logout'], { queryParams: { returnUrl } }).then((): void => {
      this.snackBar.open(
        'Vaše relace vypršela. Byl(a) jste odhlášen(a).',
        'OK',
        {
          duration: 10000,
        }
      );
    });
  }

  private extractStatusFromResponse(response: any): number | undefined {
    return this.recursivelyFindStatus(response);
  }

  private recursivelyFindStatus(object: any): number | undefined {
    if (object && typeof object === 'object') {
      if (object.errors && object.errors.status) {
        return object.errors.status;
      }

      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          const status = this.recursivelyFindStatus(object[key]);
          if (status !== undefined) {
            return status;
          }
        }
      }
    }

    return undefined;
  }
}
